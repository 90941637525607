<template>
  <div class="tlw-flex tlw-flex-col">
    <label v-if="label" :for="$attrs.id" :class="[isDisabled ? 'tlw-text-gray-500' : 'tlw-text-gray-900']">
      {{ label }}
    </label>
    <input
      :class="classes"
      v-bind="$attrs"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('blur', $event)"
      @focus="$emit('focus', $event)"
      @change="$emit('change', $event)"
    />
    <div class="tlw-text-xs tlw-mt-1">
      <slot name="feedback"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MInput',
  inheritAttrs: false,
  props: {
    value: {},
    label: {
      type: String,
      default: null,
    },
    state: {
      type: [Boolean, null],
      default: null,
    },
  },
  computed: {
    baseClass() {
      return 'tlw-bg-transparent tlw-placeholder-gray-400 tlw-px-3 tlw-py-2 tlw-border tlw-border-gray-500 tlw-rounded-sm focus:tlw-outline-none disabled:tlw-text-gray-400 disabled:tlw-border-gray-400';
    },
    stateClass() {
      switch (this.state) {
        case false:
          return 'tlw-border-red-700 focus:tlw-shadow-red';
        case true:
          return 'focus:tlw-border-green-500 focus:tlw-shadow-green';
        default:
          return 'focus:tlw-border-blue-500 focus:tlw-shadow-blue';
      }
    },
    classes() {
      return [this.baseClass, this.stateClass];
    },
    isDisabled() {
      if (Object.keys(this.$attrs).includes('disabled')) {
        if (this.$attrs.disabled === '') return true;
        else return this.$attrs.disabled;
      } else {
        return false;
      }
    },
  },
};
</script>

<style></style>
