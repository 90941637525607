




































































































































import { Component, Prop, Vue, Ref, Mixins } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { hsForm } from '@/components';
import MButton from '@/shared/components/MButton.vue';
import MInput from '@/shared/components/MInput.vue';
import InfiniteLoading from 'vue-infinite-loading';
import { studentService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import { hsLoading, hsConfirmModal } from '@/components';
import Confirm from '@/shared/mixins/Confirm';
import { Tag } from '@/types/members'

@Component({
  components: {
    hsLoading,
    hsForm,
    MButton,
    MInput,
    InfiniteLoading,
    hsConfirmModal
  },
  validations: {
        form: {
          name: {
            required,
          }
        }
    }
})
export default class TagModal extends Mixins(Confirm) {
  @Prop({ required: true }) modalId: string;
  @Prop({ default: null }) initialEditTag!: Tag | null;

  @Ref() inputRef!: any;
  @Ref() modal!: Element;

  tagList: Tag[] = [];
  form: any = {
    id: null,
    name: '',
    description: '',
  };

  isLoading: boolean = true;
  isLoadingBtn: boolean = false;
  isLoadingDelete: boolean = false;
  activeTagId: number | null = null;
  showConfirmBox!: any;
  duplicatedTag: boolean = false;
  loadMembersIfChange: boolean = false;

  page: number = 1;
  perPage: number = 4;
  totalTags: number = 0;

  prefixLang: string = 'sparkmembers.members-list.tags-modal';

  async mounted() {
    this.initialTagToEdit();
    await this.getTags();
    this.closeModal();
  }

  initialTagToEdit() {
    if(this.initialEditTag !== null) {
      this.form = {
        id: this.initialEditTag.id,
        name: this.initialEditTag.name,
        description: this.initialEditTag.description,
      }
    }
  }

  closeModal() {
    this.$root.$on('bv::modal::hide', (_: any, modalId: string) => {
      if(modalId === 'tag-modal') {
        this.$emit('closeModal', this.loadMembersIfChange);
      }
    });
  }

  async getTags() {
    try {
      const { categories, total_count } = await studentService.getUserCategories([
        { key: 'page', value: this.page },
        { key: 'per_page', value: this.perPage },
        { key: 'sort', value: 'id' },
        { key: 'direction', value: 'desc' },
      ]);
      this.tagList.push(...categories);
      this.totalTags = total_count;
    } catch (error) {
      ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.get-tags-error`));
    } finally {
      this.isLoading = false;
    }
  }

  async infiniteScrollHandler($state: any) {
    try {
      if (this.tagList.length < this.totalTags) {
        this.page += 1;
        await this.getTags();
        $state.loaded();
      } else {
        $state.complete();
      }
    } catch (error) {
      ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.get-tags-error`));
    }
  }

  async onSubmit() {
     this.form.id === null ? await this.addTag() : await this.editTag();
  }

  async addTag() {
    try {
      this.isLoadingBtn = true;
      const {
        data: { id, name, description },
      } = await studentService.createUserCategory({
        name: this.form.name,
        description: this.form.description,
      });

      this.tagList.unshift({ id, name, description });
      this.resetForm();
      this.loadMembersIfChange = true;
      ToastHelper.successMessage(this.$t(`${this.prefixLang}.toast.create-tag-success`));
    } catch (error) {
      if(error.response && error.response.status === 422) {
        this.tagIsDuplicated();
      } else {
        ToastHelper.dangerMessage(
          this.$t(`${this.prefixLang}.toast.create-tag-error`)
        );
      }
    } finally {
      this.isLoadingBtn = false;
    }
  }

  tagIsDuplicated() {
      this.duplicatedTag = true;
      ToastHelper.dangerMessage(
        this.$t(`${this.prefixLang}.toast.duplicated-tag-error`)
      );
  }

  async editTag() {
    try {
      this.isLoadingBtn = true;
      await studentService.updateUserCategory(this.form);
      this.updateTagInTagList(this.form);
      this.resetForm();
      this.loadMembersIfChange = true;
      ToastHelper.successMessage(this.$t(`${this.prefixLang}.toast.update-tag-success`));
    } catch (error) {
      ToastHelper.dangerMessage(
        this.$t(`${this.prefixLang}.toast.update-tag-error`)
      );
    } finally {
      this.isLoadingBtn = false;
    }
  }

  updateTagInTagList(tag: Tag) {
    let indexTagToUpdate = this.tagList.findIndex(t => t.id === tag.id)
    this.$set(this.tagList, indexTagToUpdate, tag)
  }

  handleTagToEdit(tag: Tag) {
    this.activeTagId = tag.id;
    this.form = {
      id: tag.id,
      name: tag.name,
      description: tag.description
    }
    this.modal.scrollTop = this.modal.scrollHeight;
    this.inputRef.$children[0]?.$el.focus()
  }

  async confirmDeleteTagModal(tag: Tag) {
    const confirm = await this.showConfirmBox({
        contentTitle: this.$t(`${this.prefixLang}.delete-tag-modal.title`),
        contentDescription: this.$t(`${this.prefixLang}.delete-tag-modal.subtitle`, { tag: tag.name }),
        okTitle: this.$t(`${this.prefixLang}.delete-tag-modal.buttons.ok`),
        cancelTitle: this.$t(`${this.prefixLang}.delete-tag-modal.buttons.cancel`),
        modalClass: 'delete-tag',
        variant: 'cherry',
        icon: 'trash-alt',
      });

    confirm && await this.deleteTag(tag.id);
  }

  async deleteTag(tag_id: number) {
    try {
      this.activeTagId = tag_id;
      this.isLoadingDelete = true;
      await studentService.deleteUsersCategory(tag_id);
      const tagList = this.tagList.filter((tag: any) => tag.id !== tag_id);
      this.tagList = tagList;
      this.loadMembersIfChange = true;
      ToastHelper.successMessage(this.$t(`${this.prefixLang}.toast.delete-tag-success`));
    } catch (error) {
      ToastHelper.dangerMessage(
        this.$t(`${this.prefixLang}.toast.delete-tag-error`)
      );
    } finally {
      this.activeTagId = null;
      this.isLoadingDelete = false;
    }
  }

  resetForm() {
    this.activeTagId = null;
    this.duplicatedTag = false;
    this.$v.form.name?.$reset();
    this.form = {
      id: null,
      name: '',
      description: '',
    };
  }
}
